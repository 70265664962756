<template>
  <v-col cols="4" v-if="active">
    <v-row>
      <a class="pr-2" target="_blank" :href="getUrl(icon, url)">
        <v-icon large :color="color ? 'black' : 'white'">
          {{ iconClass }}
        </v-icon>
      </a>
    </v-row>
    <template>
      <v-row
        :style="{
          color: color ? 'black' : 'white'
        }"
      >
        {{ url }}
      </v-row>
    </template>
  </v-col>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    },
    color: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    iconClass() {
      const iconClasses = {
        facebook: 'fab fa-facebook-square',
        x: 'fab fa-twitter-square',
        instagram: 'fab fa-instagram-square'
      }
      return iconClasses[this.icon] || ''
    }
  },
  methods: {
    getUrl(icon, url) {
      const baseUrls = 'https://www.' + icon + '.com/'
      return baseUrls ? baseUrls + url.replace('@', '') : '#'
    }
  }
}
</script>
